import React, { type FC } from 'react';
import logo from '../../assets/images/logo.png';

export const LogoIcon: FC = () => {
  const fillColor = 'var(--CFD-theme-System-OnSecondaryContainer)';

  return (
    <img src={logo} alt="logo" />
  )
}

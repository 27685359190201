import React, { type FC } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { useAppSelector } from '../../hooks/redux';
import { TradeIcon } from '../icons/TradeIcon'
import { LogoIcon } from '../icons/LogoIcon'
import { ProfileIcon } from '../icons/ProfileIcon'
import { AccountsIcon } from '../icons/AccountsIcon'
import { SavingsIcon } from '../icons/SavingsIcon'
import { ThemeToggle } from '../../components';
import { type RootState } from '../../redux/store';
import { TradeElementHeight } from '../../pages/Trade/helper'

import s from './NavigationRail.module.css'

export interface MenuItemInterface {
  link: string
  logo: JSX.Element
  title: string
}

export const menuItemsData: MenuItemInterface[] = [
  {
    link: '/trade?asset=EURUSD',
    logo: <TradeIcon />,
    title: t('labels.trading')
  },
  {
    link: '/profile',
    logo: <ProfileIcon />,
    title: t('labels.profile')
  },
  {
    link: '/accounts',
    logo: <AccountsIcon />,
    title: t('labels.accounts')
  },
  {
    link: '/savings',
    logo: <SavingsIcon />,
    title: t('labels.savings')
  }
]

export const NavigationRail: FC = () => {
  const isAlertMessage = useAppSelector((state: RootState) => state.alertMessage.isAlertMessage);
  const location = useLocation();
  const { brand } = useAppSelector((state) => state.user);
  const isAlertMessageShown = (isAlertMessage && location.pathname.includes('/trade'));

  return (
    <div
      className={s.navRailWrapper}
      style={{
        top: isAlertMessageShown ? `${TradeElementHeight.AlertMessage}px` : '0',
        height: isAlertMessageShown ? `calc(100% - ${TradeElementHeight.AlertMessage}px)` : '100%'
      }}
    >
      <div className={s.logoBlock}>
      {
        ((brand?.landingUrl) != null)
          ? <a href={ brand?.landingUrl } target="_blank" rel="noreferrer"><LogoIcon /></a>
          : <LogoIcon />
      }
      </div>
      <MenuList data={menuItemsData} />
    </div>
  )
}

interface MenuPropsType {
  data: MenuItemInterface[]
}

const MenuList = ({ data }: MenuPropsType): JSX.Element => {
  const location = useLocation();

  return (
    <div className={s.menu}>
      {
        data.map(({ link, logo, title }: MenuItemInterface, key) => {
          const isSelected = link === location.pathname || link.startsWith(location.pathname);

          return (
            <div className={s.menuItem} key={key}>
              <NavLink to={link}>
                <div className={`${s.menuItemImageWrapper} ${isSelected ? s.selectedMenuImageItem : null}`}>
                  {logo}
                </div>
              </NavLink>
              <div className={`${s.menuItemTitleWrapper}`}>
                <NavLink to={link} style={{ textDecoration: 'none' }}>
                  <span className={`${s.menuLink} ${isSelected ? s.selectedMenuItem : null}`}>
                    {title}
                  </span>
                </NavLink>
              </div>
            </div>
          )
        })
      }
      <ThemeToggle />
    </div>
  )
}
